import { EnricherConfiguration } from '@wix/ambassador-reviews-v1-enriched-review/types';
import { AllActions } from '../store-types';
import {
  FETCH_DEEP_LINK_SUCCESS,
  FETCH_REVIEWS_INITIAL_SUCCESS,
} from '../reviews/paging/reviews-paging-actions';

export const configurationReducer = (state: EnricherConfiguration = {}, action: AllActions) => {
  switch (action.type) {
    case FETCH_DEEP_LINK_SUCCESS:
    case FETCH_REVIEWS_INITIAL_SUCCESS: {
      return action.payload.response.configuration ?? state;
    }
    default:
      return state;
  }
};
