import { Sorting } from '~settings-commons/constants/sorting';
import { unreachable } from '../../ts-utils';
import { Ordering } from './controller/lib/reviews-api-types';

export const resolveSorting = (order: Sorting): Ordering => {
  switch (order) {
    case Sorting.Newest:
      return 'NEWEST';
    case Sorting.TopRated:
      return 'HIGHEST_RATED';
    case Sorting.MostHelpful:
      return 'HELPFUL';
    default:
      throw unreachable(order);
  }
};
