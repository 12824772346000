import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { ReviewsBinding, SharedAppStateStore } from '~/shared-app-state/shared-app-state';
import { SlotContext } from '~/types/common-types';
import { resolveDefaultNamespace } from '~commons/resolve-default-namespace';
import { Sorting } from '~settings-commons/constants/sorting';
import { REVIEWS_APP_ID } from '../../app-ids';
import { ReviewsSlotInterface } from './common/common-types';
import { setBaseParams, updateInstance } from './common/store/base-params/base-params-actions';
import { initBiMiddleware } from './common/store/middleware/bi-middleware';
import { createRatingsWathcer } from './common/store/reviews/create-ratings-watcher';
import { createReviewsWathcer } from './common/store/reviews/created-reviews-watcher';
import { refetchReviewStates } from './common/store/reviews/paging/reviews-paging-actions';
import { initBeforeCrud } from './common/store/reviews/reviews-before-crud-interceptor';
import { createConfigurationStore } from './controller/configuration-store';
import { controllerActions } from './controller/controller-actions';
import { createReduxStore, ReduxStore } from './controller/create-redux-store';
import { createInstanceManager } from '~commons/instance/get-instance';
import { decorateActionsWithLogger } from '~commons/lib/monitoring';
import { createStateOptimizer } from './controller/lib/state-optimizer';
import { createSettingsEventHandler } from '~commons/settings/create-settings-event-handler';
import { resolveSorting } from './resolve-sorting';
import settingsParams from './settingsParams';
import { initDevToolsStore } from './Widget/components/dev-pannel/init-dev-tool-store';
import { getSeoTags } from './controller/seo-tags';
import { STORES_NAMESPACE } from '~/external-ids';
import { resolveQueryParams } from './common/services/query-params';
import { unreachable } from '~/ts-utils';
import {
  OPEN_SEGMENT_EVENT,
  OPEN_TAB_EVENT,
  SET_FORM_EFFECT_EVENT,
  UPDATE_DEFAULT_SORTING_EVENT,
  UPDATE_PAGE_SIZE_EVENT,
} from './common/settings/events';
import { getEnvParams } from '~commons/get-env-params';
import { ReviewsSettingsEvent } from '~reviews/common/settings/events';

const createController: CreateControllerFn = async (controllerParams) => {
  if (process.env.NODE_ENV === 'test') {
    // @ts-expect-error
    window.exposeControllerParams?.(controllerParams);
  }
  const flowAPI = controllerParams.flowAPI;
  const { setProps, wixCodeApi, config, compId } = flowAPI.controllerConfig;
  const { isSSR, isDebug, logNetwork } = getEnvParams(wixCodeApi);

  flowAPI.errorMonitor?.addBreadcrumb({ data: { url: wixCodeApi.location.url } });
  const sharedAppStateStore: SharedAppStateStore | undefined =
    controllerParams.appData?.sharedAppStateStore;
  let sharedStoreBinding: ReviewsBinding | undefined;
  let tryOpenReviewForm = noop;
  let tryCloseReviewForm = noop;
  const settingsEventHandler = createSettingsEventHandler<ReviewsSettingsEvent>(
    config.publicData.COMPONENT,
  );

  const slotContext: SlotContext | undefined =
    flowAPI.controllerConfig.config.publicData.COMPONENT?.slotContext;

  const defaultNamespace = resolveDefaultNamespace(
    slotContext,
    flowAPI.controllerConfig.compId,
    flowAPI.experiments.enabled('specs.wixReviews.useStoresAsDefaultNamespace')
      ? flowAPI.controllerConfig.type
      : '',
  );

  const warmupResourceIdKey = `wix-reviews-${compId}-resourceId`;
  const warmupNamespaceKey = `wix-reviews-${compId}-namespace`;
  const warmupResourceId = isSSR ? null : wixCodeApi.window.warmupData.get(warmupResourceIdKey);
  const warmupNamespace = isSSR ? null : wixCodeApi.window.warmupData.get(warmupNamespaceKey);

  const configurationStore = createConfigurationStore({
    defaultNamespace: warmupNamespace || defaultNamespace,
    defaultResourceId: warmupResourceId || wixCodeApi.site.currentPage?.id || 'default',
    defaultWaitForResourceId:
      slotContext?.type === 'slot' && !flowAPI.environment.isEditor && isSSR,
  });
  const exports = (): ReviewsSlotInterface & any => {
    return {
      setResourceId: (resourceId: string) => {
        configurationStore.setResourceId(resourceId);
      },
      setNamespace: (namespaceOverride: string) => {
        configurationStore.setNamespace(namespaceOverride);
      },
      waitForResourceSet: () => {
        configurationStore.setWaitForResourceId(true);
      },
      tryOpenReviewForm: () => tryOpenReviewForm(),
      tryCloseReviewForm: () => tryCloseReviewForm(),

      // ----------- SLOTS INTERFACE ------------
      set resourceId(val: string) {
        configurationStore.setResourceId(val);
        isSSR && wixCodeApi.window.warmupData.set(warmupResourceIdKey, val);
      },
      set namespace(val: string) {
        configurationStore.setNamespace(val);
        isSSR && wixCodeApi.window.warmupData.set(warmupNamespaceKey, val);
      },
      set waitForResourceId(val: boolean) {
        configurationStore.setWaitForResourceId(val);
      },
    };
  };

  if (process.env.NODE_ENV === 'test') {
    // Yoshi testkit doesn't expose exports :(
    // @ts-expect-error
    window.exposeExports && window.exposeExports(exports());
  }

  return {
    async pageReady() {
      // @TODO structure this function
      const appDefinitionId = REVIEWS_APP_ID;

      // Dev states
      const devToolsApi = initDevToolsStore({
        onChange: (state, prevState) => {
          if (state.specialStates === 'crash') {
            invokeAppCrash();
          }
          setProps({ devToolsState: state });
        },
        wixCodeApi,
        isDebug,
      });

      if (devToolsApi.getState().specialStates === 'crash') {
        invokeAppCrash();
      }

      // store
      const reviewsWatcher = createReviewsWathcer();
      const ratingsWatcher = createRatingsWathcer();
      const beforeCreateInterceptor = initBeforeCrud();

      const instanceManager = createInstanceManager(wixCodeApi, appDefinitionId);

      const biMiddleware = initBiMiddleware(instanceManager, flowAPI);

      const store = createReduxStore({
        flowApi: flowAPI,
        biMiddleware,
        isSSR,
        instanceManager,
        devToolsApi,
        reviewsWatcherMidlleware: reviewsWatcher.middleware,
        ratingsWatcherMiddleware: ratingsWatcher.middleware,
        executeBeforeCrud: beforeCreateInterceptor.executeBeforeCrud,
        logNetwork,
      });

      const actions = decorateActionsWithLogger(
        bindActionCreators(controllerActions, store.dispatch),
        flowAPI.errorMonitor,
        isDebug || logNetwork === 'error',
      );

      // handle app token refresh
      instanceManager.onChange(({ instance }) => {
        store?.dispatch(updateInstance({ instance }));
      });

      await initUserDependentStoreBaseData({
        store,
        appDefinitionId,
      });

      wixCodeApi.user.onLogin(() => {
        setTimeout(() => {
          initUserDependentStoreBaseData({ store, appDefinitionId });
          store.dispatch(refetchReviewStates());
        });
      });

      tryOpenReviewForm = actions.openReviewForm;
      tryCloseReviewForm = actions.closeReviewForm;

      // Fetching
      const pagination = {
        ordering: resolveSorting(flowAPI.settings.get(settingsParams.defaultSorting) as Sorting),
        pageSize: flowAPI.settings.get(settingsParams.reviewCount),
      };

      const configurationResult = await configurationStore.getConfiguration();
      if (configurationResult.type === 'error') {
        const error = new Error(configurationResult.error);
        flowAPI.errorMonitor.captureException(error, {
          tags: {
            warmupResourceId: warmupResourceId ?? 'NONE',
            warmupNamespace: warmupNamespace ?? 'NONE',
            type: 'Reviews controller',
            url: wixCodeApi.location.url,
            configState: JSON.stringify(configurationStore.getConfigState()),
            getState: JSON.stringify(configurationStore.getState()),
          },
        });
        throw error;
      }
      const { resourceId: initialResourceId, namespace: initialNamespace } =
        configurationResult.value;

      sharedStoreBinding = sharedAppStateStore?.registerReviews({
        resourceId: initialResourceId,
        namespace: initialNamespace,
        focusRoot: actions.focusRoot,
      });

      ratingsWatcher.watcher.subscribe((s) => {
        sharedStoreBinding?.setState(s);
      });

      configurationStore.subscribe(({ resourceId, namespace }) => {
        sharedStoreBinding?.reconfigure({ resourceId, namespace });
        actions.fetchReviewsInitial({ resourceId, pagination, namespace });
      });

      const queryCommand = resolveQueryParams({
        query: wixCodeApi.location.query,
        namespace: initialNamespace,
        resourceId: initialResourceId,
        inSlot: slotContext?.type === 'slot',
      });

      switch (queryCommand.command) {
        case 'none':
        case 'openForm':
        case 'focusRoot': {
          await actions.fetchReviewsInitial({
            resourceId: initialResourceId,
            pagination,
            namespace: initialNamespace,
          });

          if (queryCommand.command === 'openForm') {
            actions.openReviewForm();
          }
          if (queryCommand.command === 'focusRoot') {
            actions.focusRoot();
          }

          break;
        }
        case 'openDeepLink': {
          // We don't want controller to resolve before reviews are fetched, so we do await here
          await actions.fetchDeepLink({
            resourceId: initialResourceId,
            pagination,
            namespace: initialNamespace,
            reviewId: queryCommand.reviewId,
          });
          break;
        }
        default: {
          console.error('Unimplemented queryCommand', unreachable(queryCommand));
        }
      }

      const seoTags = getSeoTags(store.getState().reviews, {
        namespace: initialNamespace,
        resourceId: initialResourceId,
      });
      if (seoTags && initialNamespace === STORES_NAMESPACE) {
        wixCodeApi.seo.renderSEOTags({ itemType: 'REVIEWS_COMPONENT', itemData: seoTags });
      }

      const stateOptimizer = createStateOptimizer(store, (optimizedState) =>
        setProps({ optimizedState }),
      );
      // In ssr patch without initial state would be end in fatal error, so we disable it in ssr;
      if (isSSR || flowAPI.environment.isEditor) {
        stateOptimizer.disable();
      }

      setProps({
        reviewId: wixCodeApi.location.query.reviewsDeepLinkId,

        optimizedState: {
          type: 'SET',
          state: store.getState(),
        },
        devToolsApi,
        devToolsState: devToolsApi.getState(),
        requestFullState: stateOptimizer.requestFullState,
        actions,

        // https://wix.slack.com/archives/CAKBA7TDH/p1646144638497679?thread_ts=1643623637.998229&cid=CAKBA7TDH
        fitToContentHeight: true,
      });

      stateOptimizer.subscribe();

      // settings
      settingsEventHandler.on(OPEN_TAB_EVENT, (payload) => {
        if (payload.tabName === 'Design') {
          return;
        }
        actions.closeReviewForm();
      });

      settingsEventHandler.on(OPEN_SEGMENT_EVENT, (payload) => {
        if (payload.segmentName === 'ReviewForm') {
          actions.setFormEffect('Default');
          return actions.openReviewForm();
        }
        actions.closeReviewForm();
      });

      settingsEventHandler.on(UPDATE_DEFAULT_SORTING_EVENT, (payload) => {
        actions.changeOrdering({ ordering: resolveSorting(payload.sorting) });
      });

      settingsEventHandler.on(UPDATE_PAGE_SIZE_EVENT, (payload) => {
        actions.changePageSize({ pageSize: payload.size });
      });

      settingsEventHandler.on(SET_FORM_EFFECT_EVENT, (payload) => {
        actions.setFormEffect(payload);
      });

      settingsEventHandler.onSettingsClose(() => {
        actions.closeReviewForm();
      });
    },
    updateConfig(_, { publicData }) {
      settingsEventHandler.updateData(publicData.COMPONENT ?? {});
    },
    exports,
    onBeforeUnLoad() {
      sharedStoreBinding?.unregister();
    },
  };
};

function initUserDependentStoreBaseData({
  store,
  appDefinitionId,
}: {
  store: ReduxStore;
  appDefinitionId: string;
}) {
  return store.dispatch(setBaseParams({ appDefinitionId }));
}

const invokeAppCrash = () => {
  throw new Error(DUMMY_ERROR);
};

export const DUMMY_ERROR = 'This is a dummy error';
export default createController;
