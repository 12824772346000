import { ReviewContent } from '../../common/store/reviews/reviews/review-state-types';
import { ConnectionState } from '../../common/dev-tools-types';
import { list } from '@wix/ambassador-reviews-v1-enriched-review/http';
import {
  Fieldset,
  Review as ServerReview,
  ModerationStatus,
} from '@wix/ambassador-reviews-v1-enriched-review/types';
import {
  createReviewAndContact as apiCreateReview,
  updateReviewContent as apiUpdateReview,
  deleteReview as apiDeleteReview,
  setReply as apiSetReply,
  removeReply as apiRemoveReply,
  getReview,
} from '@wix/ambassador-reviews-v1-review/http';
import {
  fixInconsistentRatingsBreakdown,
  fromServerListResponse,
  fromServerListResponseFull,
  fromServerReply,
  toServerReviewContent,
  fromServerReview,
} from './reviews-api-mappers';
import { assertDefined, assertFields } from '~/ts-utils';
import {
  Author,
  CreateAs,
  FetchReviewsResponse,
  FetchReviewsResponseFull,
  ServerOrdering,
} from './reviews-api-types';
import {
  upvote as apiUpvote,
  downvote as apiDownvote,
  deleteVote as apiRemoveVote,
} from '@wix/ambassador-reviews-v1-vote/http';
import { withMiddlewareDevTools } from './middleware-dev-tools';
import { PaginationConfig } from '../../common/common-types';
import { IHttpClient, ControllerParams } from '@wix/yoshi-flow-editor';
import { fetchReviewsEditor } from './reviews-api-editor-mock';
import { ModerationModerationStatus } from '@wix/ambassador-reviews-v1-review/types';
import { InstanceManager } from '~commons/instance/get-instance';

type Request = IHttpClient['request'];

export function createReviewsApi({
  httpClient,
  getCrudDevState,
  getFetchDevState,
  logNetwork,
  flowApi,
  instanceManager,
}: {
  httpClient: IHttpClient;
  getCrudDevState: () => ConnectionState;
  getFetchDevState: () => ConnectionState;
  trackError?: (e: any, args: any) => void;
  logNetwork: 'none' | 'all' | 'error';
  flowApi: ControllerParams['flowAPI'];
  instanceManager: InstanceManager;
}) {
  const { isEditor, isPreview } = flowApi.environment;
  const showMockData = isEditor || isPreview || instanceManager.getInstanceValues().siteIsTemplate;
  const trackError =
    logNetwork === 'error'
      ? (error: any, args: any) => console.error(`NETWORK ERROR `, error, '\nArguments', args)
      : undefined;

  const request: Request =
    logNetwork === 'all'
      ? (ambassadorFn: any) => {
          // Currently it handles only RequestFunctionFactory case
          let options = {} as any;
          const ambassadorFnWrapped = (ctx: any) => {
            const result = ambassadorFn(ctx);
            options = result;
            return result;
          };
          return httpClient.request(ambassadorFnWrapped).then((response: any) => {
            console.log(
              `WixReviews ${options.url || 'uknown_url'} ${response.requestId}`,
              'options',
              options,
              'response',
              response,
            );
            return response;
          });
        }
      : httpClient.request.bind(httpClient);

  const crudApi = {
    createReview: createReview(request),
    updateReview: updateReview(request),
    deleteReview: deleteReview(request),

    setReply: setReply(request),
    removeReply: removeReply(request),

    upvote: upvote(request),
    downvote: downvote(request),
    removeVote: removeVote(request),
  };

  const fetchApi = {
    fetchReviewsInitial: showMockData ? fetchReviewsEditor(isEditor) : fetchReviewsInitial(request),
    fetchDeepLink: fetchReviewsDeepLink(request),
    fetchReviewsChunk: showMockData ? fetchReviewsEditor(isEditor) : fetchReviewsChunk(request),
    fetchReviews: showMockData ? fetchReviewsEditor(isEditor) : fetchReviews(request),
  };

  return {
    ...withMiddlewareDevTools(crudApi, getCrudDevState, trackError),
    ...withMiddlewareDevTools(fetchApi, getFetchDevState, trackError),
  };
}

const enrichWithModerationStatus = async (
  request: Request,
  review: ServerReview,
  isModerationEnabled: boolean,
): Promise<ServerReview> => {
  if (!isModerationEnabled) {
    return { ...review, moderation: { moderationStatus: ModerationStatus.APPROVED } };
  }
  try {
    assertFields(review, ['id'], 'review');
    let timedOut = false;
    let moderationStatus = ModerationStatus.IN_MODERATION;
    // we hope that 3s is enough time to apply moderation rules
    setTimeout(() => {
      timedOut = true;
    }, 3000);
    for (let i = 0; i < 3; i++) {
      const throttleTimeout = new Promise((res) => setTimeout(res, 1000));
      const response = (await request(getReview({ reviewId: review.id }))).data;
      const status = response?.review?.moderation?.moderationStatus;
      if (status && status !== ModerationModerationStatus.SUBMITTED) {
        moderationStatus = status as unknown as ModerationStatus;
        break;
      }
      if (timedOut) {
        break;
      }
      await throttleTimeout;
    }
    return {
      ...review,
      moderation: {
        moderationStatus,
      },
    };
  } catch (e) {
    return { ...review, moderation: { moderationStatus: ModerationStatus.IN_MODERATION } };
  }
};

const createReview =
  (request: Request) =>
  async ({
    reviewContent,
    resourceId,
    createAs,
    namespace,
    isModerationEnabled,
  }: {
    reviewContent: ReviewContent;
    resourceId: string;
    createAs: CreateAs;
    namespace: string;
    isModerationEnabled: boolean;
  }) => {
    if (createAs.type === 'MEMBER') {
      const response = await request(
        apiCreateReview({
          namespace,
          entityId: resourceId,
          content: toServerReviewContent(reviewContent),
        }),
      );

      assertFields(response.data, ['review'], 'createReview response');
      return fromServerReview({
        review: await enrichWithModerationStatus(
          request,
          // @ts-expect-error remove after moderation field migration
          response.data.review,
          isModerationEnabled,
        ),
        currentMember: createAs.member,
      });
    }
    const response = await request(
      apiCreateReview({
        namespace,
        entityId: resourceId,
        content: toServerReviewContent(reviewContent),
        ...createAs.contact,
      }),
    );

    assertFields(response.data, ['review'], 'createReview response');
    return fromServerReview({
      // @ts-expect-error remove after moderation field migration
      review: await enrichWithModerationStatus(request, response.data.review, isModerationEnabled),
    });
  };

const updateReview =
  (request: Request) =>
  async ({
    reviewId,
    reviewContent,
    updatedFields,
    author,
    isModerationEnabled,
  }: {
    reviewId: string;
    reviewContent: ReviewContent;
    updatedFields: (keyof ReviewContent)[];
    author: Author;
    isModerationEnabled: boolean;
  }) => {
    const response = await request(
      apiUpdateReview({
        reviewId,
        content: toServerReviewContent(reviewContent),
        mask: updatedFields.map((f) => `content.${f}`),
      }),
    );

    assertFields(response.data, ['review'], 'updateReview response');
    return fromServerReview({
      // @ts-expect-error remove after moderation field migration
      review: await enrichWithModerationStatus(request, response.data.review, isModerationEnabled),
      author,
    });
  };

const deleteReview =
  (request: Request) =>
  async ({ reviewId }: { reviewId: string }) => {
    await request(apiDeleteReview({ reviewId }));
  };

const setReply =
  (request: Request) =>
  async ({ replyContent, parentId }: { replyContent: string; parentId: string }) => {
    const response = await request(
      apiSetReply({
        reviewId: parentId,
        message: replyContent,
      }),
    );

    const reply = response.data.review?.reply;
    assertDefined(reply, 'reply in set reply response');
    return fromServerReply(reply);
  };

const removeReply =
  (request: Request) =>
  async ({ reviewId }: { reviewId: string }) => {
    await request(apiRemoveReply({ reviewId }));
  };

const fetchReviewsInitial =
  (request: Request) =>
  async ({
    resourceId,
    pagination,
    namespace,
  }: {
    resourceId: string;
    pagination: PaginationConfig;
    namespace: string;
  }): Promise<FetchReviewsResponseFull> => {
    const response = (
      await request(
        list({
          contextId: resourceId,
          namespace,
          limit: pagination.pageSize,
          sorting: pagination.ordering as ServerOrdering,
          ...(pagination.ratingFilter && { ratingFilter: pagination.ratingFilter }),
          fieldsets: [
            Fieldset.RATING_SUMMARY,
            Fieldset.MEMBER_NAMES,
            Fieldset.CURRENT_MEMBER,
            Fieldset.CURRENT_USER_VOTES,
            Fieldset.CURRENT_USER_REVIEW,
            Fieldset.PERMISSIONS,
            Fieldset.CONFIGURATION,
          ],
        }),
      )
    ).data;

    return fixInconsistentRatingsBreakdown(fromServerListResponseFull(response));
  };

const fetchReviewsDeepLink =
  (request: Request) =>
  async ({
    resourceId,
    reviewId,
    namespace,
  }: {
    resourceId: string;
    reviewId: string;
    namespace: string;
  }): Promise<FetchReviewsResponseFull> => {
    const response = (
      await request(
        list({
          contextId: resourceId,
          namespace,
          reviewIdsFilter: [reviewId],
          limit: 1,
          fieldsets: [
            Fieldset.RATING_SUMMARY,
            Fieldset.MEMBER_NAMES,
            Fieldset.CURRENT_MEMBER,
            Fieldset.CURRENT_USER_VOTES,
            Fieldset.CURRENT_USER_REVIEW,
            Fieldset.PERMISSIONS,
            Fieldset.CONFIGURATION,
          ],
        }),
      )
    ).data;

    return fixInconsistentRatingsBreakdown(fromServerListResponseFull(response));
  };

const fetchReviewsChunk =
  (request: Request) =>
  async ({
    pagination,
    cursor,
    resourceId,
    namespace,
  }: {
    pagination: PaginationConfig;
    cursor: string;
    resourceId: string;
    namespace: string;
  }): Promise<FetchReviewsResponse> => {
    const response = (
      await request(
        list({
          contextId: resourceId,
          namespace,
          cursor,
          limit: pagination.pageSize,
          fieldsets: [Fieldset.MEMBER_NAMES, Fieldset.CURRENT_USER_VOTES],
        }),
      )
    ).data;

    return fromServerListResponse(response);
  };

const fetchReviews =
  (request: Request) =>
  async ({
    pagination,
    resourceId,
    namespace,
  }: {
    pagination: PaginationConfig;
    resourceId: string;
    namespace: string;
  }): Promise<FetchReviewsResponse> => {
    const response = (
      await request(
        list({
          contextId: resourceId,
          namespace,
          sorting: pagination.ordering as ServerOrdering,
          ...(pagination.ratingFilter && { ratingFilter: pagination.ratingFilter }),
          limit: pagination.pageSize,
          fieldsets: [Fieldset.MEMBER_NAMES, Fieldset.CURRENT_USER_VOTES],
        }),
      )
    ).data;

    return fromServerListResponse(response);
  };

const upvote =
  (request: Request) =>
  async ({ reviewId }: { reviewId: string }): Promise<string> => {
    const response = await request(apiUpvote({ reviewId }));
    assertFields(response.data, ['vote'], 'upvote response');
    const vote = response.data.vote;
    assertFields(vote, ['id'], 'upvote response vote');
    return vote.id;
  };

const downvote =
  (request: Request) =>
  async ({ reviewId }: { reviewId: string }): Promise<string> => {
    const response = await request(apiDownvote({ reviewId }));
    assertFields(response.data, ['vote'], 'downvote response');
    const vote = response.data.vote;
    assertFields(vote, ['id'], 'downvote response vote');
    return vote.id;
  };

const removeVote =
  (request: Request) =>
  async ({ voteId }: { voteId: string }): Promise<void> => {
    await request(apiRemoveVote({ voteId }));
  };
