import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import { TranslationKey } from '~reviews/locale-types';
import { unreachable } from '~/ts-utils';
import { ErrorInfo, ErrorLoud, ReviewCreateError } from '../../common-types';

export function getReviewCreateError(error: AmbassadorHTTPError): ReviewCreateError {
  switch (
    error.response?.status // TODO: find out why error.code and error.httpStatus is undefined.
  ) {
    case 409: {
      return {
        type: 'EMAIL_EXISTS_ERROR',
        key: 'toast.error-review-publish-email',
        ctaKey: 'toast.error-review-publish-email-cta',
      };
    }
    default: {
      return {
        type: 'TRANSLATION',
        key:
          (
            {
              '403': 'toast.error-review-publish-permission',
              '-1': 'toast.error-review-publish-connection',
            } as Record<string, TranslationKey>
          )[String(error.response?.status)] || 'toast.error-review-publish-generic',
      };
    }
  }
}

// TODO: check it - do we even get 403 when creating reply?
export function getReplyCreateError(error: AmbassadorHTTPError): ErrorLoud {
  return {
    type: 'TRANSLATION',
    key:
      (
        {
          '403': 'toast.error-review-publish-permission',
          '-1': 'toast.error-review-publish-connection',
        } as Record<string, TranslationKey>
      )[String(error.httpStatus)] || 'toast.error-review-publish-generic',
  };
}

export function getGenericError(status: number | undefined): ErrorLoud {
  return {
    type: 'TRANSLATION',
    key:
      (
        {
          '403': 'toast.error-permission',
          '-1': 'toast.error-connection',
        } as Record<string, TranslationKey>
      )[String(status)] || 'toast.error-generic',
  };
}

export function errorForBi(e: ErrorInfo | ReviewCreateError): string {
  switch (e.type) {
    case 'SILENT':
      return '';
    case 'TRANSLATION':
    case 'EMAIL_EXISTS_ERROR':
      return e.key; // @TODO  keyValue also needed here?
    case 'CUSTOM':
      return e.message;
    default:
      throw unreachable(e);
  }
}
