import { ModerationStatus } from '@wix/ambassador-reviews-v1-enriched-review/types';
import { Effects, ReviewsStateReady } from './reviews-types';
import { identity, omit } from 'lodash';
import {
  ReviewListStateNotPending,
  ReviewsListState,
  ReviewState,
} from './reviews/review-state-types';
import { RatingsBreakdown, Review } from '../../../controller/lib/reviews-api-types';
import { ReplyState } from './replies/reply-types';
import { CurrentUserVoteState } from './votes/review-vote-types';
import { unreachable } from '~/ts-utils';

/*
 * Some of state invarinats can not be expressed in type system like
 * reviewId > reviewState relationship, so this is collection of functions to help avoid
 * breaking these invarinats in reducers.
 */

export const updateReviewState = ({
  state,
  reviewId,
  updateReview,
  updateEffects = identity,
}: {
  state: ReviewsStateReady;
  reviewId: string;
  updateReview: (reviewState: ReviewState | undefined) => ReviewState | undefined;
  // Will be invoked only if update happens and merged into previous effects,
  updateEffects?: (effects: Effects, current: ReviewState | undefined) => Partial<Effects>;
}): ReviewsStateReady => {
  const [isUserReview, currentState] =
    state.userReview.type === 'CREATED' && state.userReview.review.reviewId === reviewId
      ? [true, state.userReview.review]
      : [false, state.reviewsById[reviewId]];
  const updatedState = updateReview(currentState);
  if (updatedState === currentState) {
    return state;
  }

  const updatedEffects = updateEffects(state.effects, updatedState);
  const effects =
    updatedEffects === state.effects
      ? state.effects
      : {
          ...state.effects,
          ...updatedEffects,
        };

  const ratingsBreakdown = updateRatingsBreakdown(
    state.ratingsBreakdown,
    currentState.review,
    updatedState?.review,
  );

  return {
    ...state,
    ...((): Pick<ReviewsStateReady, 'userReview'> | Pick<ReviewsStateReady, 'reviewsById'> => {
      if (isUserReview) {
        return {
          userReview: updatedState
            ? {
                type: 'CREATED',
                review: updatedState,
              }
            : { type: 'NOT_CREATED', form: { type: 'HIDDEN' } },
        };
      }
      return {
        reviewsById: updatedState
          ? {
              ...state.reviewsById,
              [reviewId]: updatedState,
            }
          : omit(state.reviewsById, reviewId),
      };
    })(),
    reviewList:
      // On removal we have to update all review list variants too
      updatedState
        ? state.reviewList
        : removeReviewFromList(state.reviewList, reviewId, isUserReview),
    effects,
    ratingsBreakdown,
  };
};

const removeReviewFromList = (
  reviewList: ReviewsListState,
  reviewId: string,
  isUserReview: boolean,
): ReviewsListState => {
  const removeReviewFromListNotPending = (
    reviewListNotPending: ReviewListStateNotPending,
  ): ReviewListStateNotPending => {
    switch (reviewListNotPending.type) {
      case 'READY':
        return {
          ...reviewListNotPending,
          prependCurrentUserReview: isUserReview
            ? false
            : reviewListNotPending.prependCurrentUserReview,
          chunk: {
            ...reviewListNotPending.chunk,
            reviewIds: reviewListNotPending.chunk.reviewIds.filter((id) => id !== reviewId),
          },
        };
      case 'ONLY_USER_REVIEW':
        return reviewListNotPending;
      case 'DEEP_LINK':
        return { ...reviewListNotPending, state: 'DELETED' };
      default:
        throw unreachable(reviewListNotPending);
    }
  };
  if (reviewList.type === 'PENDING') {
    return {
      ...reviewList,
      prevState: removeReviewFromListNotPending(reviewList.prevState),
    };
  }
  return removeReviewFromListNotPending(reviewList);
};

const updateRatingsBreakdown = (
  breakdown: RatingsBreakdown,
  currentReview: Review,
  updatedReview?: Review,
): RatingsBreakdown => {
  const isPublished = (r: Review) => r.moderationStatus === ModerationStatus.APPROVED;
  const currentRating = currentReview.content.rating;
  const updatedRating = updatedReview?.content.rating;
  return currentRating === updatedRating
    ? breakdown
    : {
        ...breakdown,
        [currentRating]: breakdown[currentRating] - (isPublished(currentReview) ? 1 : 0),
        ...(updatedRating && isPublished(updatedReview)
          ? { [updatedRating]: breakdown[updatedRating] + 1 }
          : {}),
      };
};

export const updateReplyState = ({
  state,
  parentId,
  updateReply,
  updateEffects = identity,
}: {
  state: ReviewsStateReady;
  parentId: string;
  updateReply: (replyState: ReplyState | undefined) => ReplyState | undefined;
  // Will be invoked only if update happens and merged into previous effects,
  updateEffects?: (effects: Effects, current: ReplyState | undefined) => Partial<Effects>;
}): ReviewsStateReady => {
  return updateReviewState({
    state,
    reviewId: parentId,
    updateReview: (currentReview) => {
      if (!currentReview) {
        return currentReview;
      }
      const currentReply = currentReview.replyState;
      const updatedReply = updateReply(currentReply);
      if (currentReply === updatedReply) {
        return currentReview;
      }
      return { ...currentReview, replyState: updatedReply };
    },
    updateEffects: (effects, updatedReview) => updateEffects(effects, updatedReview?.replyState),
  });
};

export const updateCurrentUserReviewVote = ({
  state,
  reviewId,
  updateCurrentUserVote,
  updateEffects = identity,
}: {
  state: ReviewsStateReady;
  reviewId: string;
  updateCurrentUserVote: (currentUserVoteState: CurrentUserVoteState) => CurrentUserVoteState;
  // Will be invoked only if update happens and merged into previous effects,
  updateEffects?: (effects: Effects, current: ReviewState | undefined) => Partial<Effects>;
}): ReviewsStateReady => {
  return updateReviewState({
    state,
    reviewId,
    updateReview: (currentReview) => {
      if (!currentReview) {
        return currentReview;
      }
      const currentCurrentUserVoteState = currentReview.votes.currentUserVote;
      const updatedCurrentUserVoteState = updateCurrentUserVote(currentCurrentUserVoteState);
      if (currentCurrentUserVoteState === updatedCurrentUserVoteState) {
        return currentReview;
      }
      return {
        ...currentReview,
        votes: { ...currentReview.votes, currentUserVote: updatedCurrentUserVoteState },
      };
    },
    updateEffects: (effects, updatedReview) => updateEffects(effects, updatedReview),
  });
};
