import {
  BI_OPEN_REVIEW_ACTIONS,
  BI_SELECT_REVIEW_ACTION,
  BI_SHOW_ZERO_REVIEWS_EMPTY_STATE,
  BI_SHOW_FALLBACK_STATE,
  BI_APP_LOADED,
  BI_CLICK_LEAVE_REVIEW_BTN,
  BI_REVIEW_PUBLISH_SUCCESS,
  BI_CLICK_REPLY_BTN,
  BI_CLICK_HELPFUL_BTN,
  BI_CLICK_HELPFUL_BTN_SUCCESS,
  BiClickHelpfulBtn,
  BiClickHelpfulBtnSuccess,
  BI_OPEN_SORTING,
  BI_OPEN_FILTERING,
  BI_CHANGE_SORTING,
  BI_CHANGE_FILTERING,
  BI_OPEN_REPLY_ACTIONS,
  BI_CLICK_SHOW_MY_REVIEW,
  BI_SELECT_REPLY_ACTION,
  BI_REVIEW_DELETED,
  BI_SHOW_FILTERED_TO_ZERO_EMPTY_STATE,
  BI_CLOSE_MEDIA_MODAL,
  BI_OPEN_MEDIA_MODAL,
  BI_CLICK_PAGINATION_BTN,
  BI_CLICK_SHOW_ALL_REVIEWS_BTN,
  BI_CLICK_PUBLISH_REVIEW,
  BI_CLICK_PUBLISH_REPLY,
  BI_CANCEL_CREATING_REVIEW,
  BI_CANCEL_CREATING_REPLY,
  BI_REPLY_DELETED,
  BI_MEDIA_UPLOADED,
  BI_MEDIA_DELETED,
  BI_REPLY_PUBLISH_SUCCESS,
  BI_CLICK_SHOW_MORE_BTN,
  BI_SHOW_FAILED_TO_LOAD_EMPTY_STATE,
  BI_CLICK_CLOSE_PENDING_REVIEW_MODAL,
  BI_SHOW_PENDING_REVIEW_MODAL,
  BI_REVIEW_SUBMITTED_FOR_APPROVAL,
  BI_FORM_VALIDATION_ERROR,
  BI_APP_IS_IN_VIEWPORT,
  BI_FOCUS_FORM_BODY,
} from '../store/actions/bi-actions';
import { BiEventsExecutor } from './bi-types';
import { AUTHOR_ROLE } from './bi-constants';
import {
  reviewsSectionLoaded,
  leaveAReviewButtonClicked,
  reviewPublishedSuccessfully,
  replyButtonClicked,
  helpfulButtonClicked,
  helpfulActionPublished,
  sortReviewsOpenActions,
  filterReviewsOpenActions,
  sortReviewsActionClick,
  filterReviewsActionClick,
  dotsActionButtonOpenActions,
  showMyReviewButtonClicked,
  dotsActionButtonActionClick,
  reviewDeleted,
  emptyStateShown,
  clickOnXToCloseMediaDialog,
  mediaModalOpened,
  paginationClick,
  showAllReviewsClick,
  publishReviewButtonClick,
  publishReplyButtonClick,
  cancelCreatingReview,
  cancelCreatingReply,
  replyDeleted,
  imageAddedToReview,
  imageDeletedFromReview,
  replyPublishedSuccessfully,
  showMoreClicked,
  showReviewApprovalModal,
  newReviewSubmittedForApproval,
  closeReviewApprovalModal,
  reviewSubmissionWarning,
  reviewsSectionLoadedInViewport,
  intentClickIntoReviewSection,
} from '@wix/bi-logger-livesite-reviews/v2';
import { createActionMatcher } from './create-action-matcher';
import settingsParams from '~reviews/settingsParams';
import { getReviewsSummary } from '~reviews/common/store/reviews/ratings-selectors';
import { getReviewState } from '~reviews/common/store/reviews/reviews/reviews-state-selectors';
import { getUpvotesDisplayed } from '~reviews/common/store/reviews/votes/review-vote-selectors';
import { getInstanceValues } from '~reviews/common/store/base-params/base-params-selectors';
import { getReviewImageCount, getReviewVideoCount } from '~reviews/common/bi/bi-utils';
import { getAppState } from '~reviews/common/store/reviews/reviews-selectors';

export const biEventsExecutor = ({ action: _action, state, logger, flowAPI }: BiEventsExecutor) => {
  const { settings } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { reviews: reviewsState, members } = state;
  const appState = getAppState(reviewsState);
  let author_role = '',
    parent_resource_id = '';
  if (reviewsState.type === 'READY') {
    const { currentOwner } = members;
    const { siteOwnerId } = getInstanceValues(state);
    parent_resource_id = reviewsState.config.resourceId;
    author_role =
      currentOwner.type === 'MEMBER'
        ? currentOwner.member.id === siteOwnerId
          ? AUTHOR_ROLE.OWNER
          : AUTHOR_ROLE.MEMBER
        : AUTHOR_ROLE.ANONYMOUS;
  }
  const getVotingData = (action: BiClickHelpfulBtn | BiClickHelpfulBtnSuccess) => {
    const { reviewId, action: voteAction } = action.payload;
    const votesState = getReviewState(reviewId, reviewsState)?.votes;
    if (!votesState) {
      return;
    }
    const getAction = () => {
      switch (votesState.currentUserVote.type) {
        case 'NEUTRAL':
          return voteAction;
        case 'UPVOTED':
          return 'downvote';
        case 'DOWNVOTED':
          return 'upvote';
      }
    };
    const votes = getUpvotesDisplayed(votesState);
    return {
      action: getAction(),
      downvotes_count: votes.downvotes.score,
      parent_resource_id,
      review_id: reviewId,
      upvotes_count: votes.upvotes.score,
    };
  };
  const getReferer = () => {
    switch (wixCodeApi.location.query.referer) {
      case 'email':
        return 'email_notification';
      default:
        return '';
    }
  };
  createActionMatcher(logger)
    .match([BI_APP_LOADED], reviewsSectionLoaded, () => {
      if (reviewsState.type === 'READY') {
        const pageSize = reviewsState.config.pagination.pageSize;
        const reviewSummary = getReviewsSummary(reviewsState);
        const isBreakdownEnabled = settings.get(settingsParams.isReviewBarchartEnabled);
        return {
          avg_rating: reviewSummary.overall,
          origin: 'product_page',
          layout_type: isBreakdownEnabled ? 'breakdown' : 'lean',
          page_count: Math.ceil(reviewSummary.totalReviews / pageSize),
          reviews_count: reviewSummary.totalReviews,
          parent_resource_id,
        };
      }
    })
    .match([BI_APP_IS_IN_VIEWPORT], reviewsSectionLoadedInViewport, () => {
      if (reviewsState.type === 'READY') {
        const pageSize = reviewsState.config.pagination.pageSize;
        const reviewSummary = getReviewsSummary(reviewsState);
        const isBreakdownEnabled = settings.get(settingsParams.isReviewBarchartEnabled);
        return {
          avg_rating: reviewSummary.overall,
          origin: 'product_page',
          layout_type: isBreakdownEnabled ? 'breakdown' : 'lean',
          page_count: Math.ceil(reviewSummary.totalReviews / pageSize),
          reviews_count: reviewSummary.totalReviews,
          parent_resource_id,
        };
      }
    })
    .match([BI_CLICK_LEAVE_REVIEW_BTN], leaveAReviewButtonClicked, (action) => ({
      parent_resource_id,
      origin: action.payload.origin,
      author_role,
      referral_info: getReferer(),
    }))
    .match([BI_REVIEW_PUBLISH_SUCCESS], reviewPublishedSuccessfully, (action) => {
      const { review, isEdited } = action.payload;
      return {
        author_role,
        image_count: getReviewImageCount(review),
        video_count: getReviewVideoCount(review),
        is_edited: isEdited ?? false,
        parent_resource_id,
        rating: review.content.rating,
        review_id: review.id,
        review_text: review.content.body ?? '',
        review_title: review.content.title ?? '',
        referral_info: getReferer(),
        approved_by: '',
        approver_role: '',
        is_verified: review.verified,
        reviews_count: 1,
      };
    })
    .match([BI_CLICK_REPLY_BTN], replyButtonClicked, (action) => ({
      author_role,
      parent_resource_id,
      review_id: action.payload.reviewId,
    }))
    .match([BI_CLICK_HELPFUL_BTN], helpfulButtonClicked, (action) => {
      const data = getVotingData(action);
      if (data) {
        return {
          ...data,
          role: author_role,
        };
      }
    })
    .match([BI_CLICK_HELPFUL_BTN_SUCCESS], helpfulActionPublished, (action) =>
      getVotingData(action),
    )
    .match([BI_CLICK_SHOW_MORE_BTN], showMoreClicked, (action) => ({
      action_type: action.payload.isShowMore ? 'show_more' : 'show_less',
      origin: action.payload.origin,
      parent_resource_id,
      review_id: action.payload.reviewId,
    }))
    .match([BI_OPEN_SORTING], sortReviewsOpenActions, () => {
      if (reviewsState.type === 'READY') {
        const reviewSummary = getReviewsSummary(reviewsState);
        return {
          parent_resource_id,
          total_reviews: reviewSummary.totalReviews,
        };
      }
    })
    .match([BI_OPEN_FILTERING], filterReviewsOpenActions, () => {
      if (reviewsState.type === 'READY') {
        const reviewSummary = getReviewsSummary(reviewsState);
        return {
          parent_resource_id,
          total_reviews: reviewSummary.totalReviews,
        };
      }
    })
    .match([BI_CHANGE_SORTING], sortReviewsActionClick, (action) => {
      const getSortBy = () => {
        switch (action.payload.ordering) {
          case 'NEWEST':
            return 'newest';
          case 'OLDEST':
            return 'oldest';
          case 'HELPFUL':
            return 'helpful';
          case 'HIGHEST_RATED':
            return 'highest_rated';
          case 'LOWEST_RATED':
            return 'lowest_rated';
        }
      };
      if (reviewsState.type === 'READY') {
        const reviewSummary = getReviewsSummary(reviewsState);
        return {
          parent_resource_id,
          total_reviews: reviewSummary.totalReviews,
          sort_by: getSortBy(),
        };
      }
    })
    .match([BI_CHANGE_FILTERING], filterReviewsActionClick, (action) => {
      if (reviewsState.type === 'READY') {
        const reviewSummary = getReviewsSummary(reviewsState);
        return {
          origin: action.payload.origin,
          parent_resource_id,
          total_reviews: reviewSummary.totalReviews,
          filtered_by: action.payload.rating === '' ? 'all_stars' : action.payload.rating,
        };
      }
    })
    .match([BI_OPEN_REVIEW_ACTIONS], dotsActionButtonOpenActions, (action) => ({
      parent_resource_id,
      role: author_role,
      type: 'review',
      review_id: action.payload.reviewId,
    }))
    .match([BI_OPEN_REPLY_ACTIONS], dotsActionButtonOpenActions, (action) => ({
      parent_resource_id,
      role: author_role,
      type: 'reply',
      review_id: action.payload.reviewId,
    }))
    .match([BI_CLICK_SHOW_MY_REVIEW], showMyReviewButtonClicked, () => {
      if (reviewsState.type === 'READY' && reviewsState.userReview.type === 'CREATED') {
        return {
          parent_resource_id,
          role: author_role,
          review_id: reviewsState.userReview.review.reviewId,
        };
      }
    })
    .match([BI_SELECT_REVIEW_ACTION], dotsActionButtonActionClick, (action) => ({
      action: action.payload.action,
      parent_resource_id,
      role: author_role,
      review_id: action.payload.reviewId,
      type: 'review',
    }))
    .match([BI_SELECT_REPLY_ACTION], dotsActionButtonActionClick, (action) => ({
      action: action.payload.action,
      parent_resource_id,
      role: author_role,
      review_id: action.payload.reviewId,
      type: 'reply',
    }))
    .match([BI_REVIEW_DELETED], reviewDeleted, (action) => {
      const { reviewId } = action.payload;
      const reviewState = getReviewState(reviewId, reviewsState);
      if (!reviewState) {
        return;
      }
      const votes = getUpvotesDisplayed(reviewState.votes);
      return {
        downvotes_count: votes.downvotes.score,
        upvotes_count: votes.upvotes.score,
        parent_resource_id,
        role: author_role,
        review_id: reviewId,
        rating: reviewState.review.content.rating,
        is_verified: reviewState.review.verified,
      };
    })
    .match(
      [
        BI_SHOW_FILTERED_TO_ZERO_EMPTY_STATE,
        BI_SHOW_FAILED_TO_LOAD_EMPTY_STATE,
        BI_SHOW_ZERO_REVIEWS_EMPTY_STATE,
        BI_SHOW_FALLBACK_STATE,
      ],
      emptyStateShown,
      (action) => ({
        description: action.payload.description,
        parent_resource_id,
      }),
    )
    .match([BI_CLOSE_MEDIA_MODAL], clickOnXToCloseMediaDialog, (action) => ({
      parent_resource_id,
      review_id: action.payload.reviewId,
      role: author_role,
    }))
    .match([BI_OPEN_MEDIA_MODAL], mediaModalOpened, (action) => {
      if (reviewsState.type === 'READY') {
        const { reviewId } = action.payload;
        const reviewState = getReviewState(reviewId, reviewsState);
        if (!reviewState) {
          return;
        }
        const { review } = reviewState;
        const votes = getUpvotesDisplayed(reviewState.votes);
        return {
          downvotes_count: votes.downvotes.score,
          image_count: getReviewImageCount(review),
          video_count: getReviewVideoCount(review),
          upvotes_count: votes.upvotes.score,
          parent_resource_id,
          role: author_role,
          review_id: reviewId,
          symbol_count: review.content.body?.length ?? 0,
          rating: review.content.rating,
        };
      }
    })
    .match([BI_CLICK_PAGINATION_BTN], paginationClick, (action) => {
      if (reviewsState.type === 'READY' && reviewsState.reviewList.type === 'READY') {
        const pageSize = reviewsState.config.pagination.pageSize;
        const reviewSummary = getReviewsSummary(reviewsState);
        const filter = reviewsState.reviewList.ratingFilter;
        return {
          action: action.payload.action,
          current_page_number: reviewsState.reviewList.currentPage,
          filtered_by: filter ? filter.toString() : 'all_stars',
          parent_resource_id,
          role: author_role,
          total_pages: Math.ceil(reviewSummary.totalReviews / pageSize),
        };
      }
    })
    .match([BI_CLICK_SHOW_ALL_REVIEWS_BTN], showAllReviewsClick, () => ({
      parent_resource_id,
      role: author_role,
    }))
    .match([BI_CLICK_PUBLISH_REVIEW], publishReviewButtonClick, (action) => {
      const { review, isEdited } = action.payload;
      return {
        author_role,
        image_count: getReviewImageCount(review),
        video_count: getReviewVideoCount(review),
        is_edited: isEdited ?? false,
        parent_resource_id,
        rating: review.content.rating,
        review_id: review.id ?? '',
        referral_info: getReferer(),
      };
    })
    .match([BI_CLICK_PUBLISH_REPLY], publishReplyButtonClick, (action) => {
      const { reviewId, isEdited } = action.payload;
      const review = getReviewState(reviewId, reviewsState);
      if (review) {
        return {
          author_role,
          is_edited: isEdited ?? false,
          parent_resource_id,
          rating: review.review.content.rating,
          review_id: reviewId ?? '',
          parent_review_id: reviewId ?? '',
        };
      }
    })
    .match([BI_CANCEL_CREATING_REVIEW], cancelCreatingReview, (action) => {
      const { review, isEdited } = action.payload;
      return {
        author_role,
        image_count: getReviewImageCount(review),
        video_count: getReviewVideoCount(review),
        is_edited: isEdited ?? false,
        parent_resource_id,
        rating: review.content.rating,
        review_id: review.id ?? '',
      };
    })
    .match([BI_CANCEL_CREATING_REPLY], cancelCreatingReply, (action) => {
      const { reviewId, isEdited } = action.payload;
      const review = getReviewState(reviewId, reviewsState);
      if (review) {
        return {
          author_role,
          is_edited: isEdited ?? false,
          parent_resource_id,
          parent_review_id: reviewId ?? '',
          rating: review.review.content.rating,
          review_id: reviewId ?? '',
        };
      }
    })
    .match([BI_REPLY_DELETED], replyDeleted, (action) => ({
      parent_resource_id,
      parent_review_id: action.payload.reviewId ?? '',
      review_id: action.payload.reviewId ?? '',
    }))
    .match([BI_MEDIA_UPLOADED], imageAddedToReview, (action) => ({
      image_count: action.payload.imageCount,
      video_count: action.payload.videoCount,
      parent_resource_id,
      role: author_role,
    }))
    .match([BI_MEDIA_DELETED], imageDeletedFromReview, () => ({
      parent_resource_id,
      role: author_role,
    }))
    .match([BI_REPLY_PUBLISH_SUCCESS], replyPublishedSuccessfully, (action) => {
      const { isEdited, reviewId, replyText } = action.payload;
      const review = getReviewState(reviewId, reviewsState);
      if (review) {
        return {
          author_role,
          is_edited: isEdited,
          parent_resource_id,
          parent_review_id: reviewId ?? '',
          rating: review.review.content.rating,
          review_id: reviewId ?? '',
          review_title: replyText,
        };
      }
    })
    .match([BI_SHOW_PENDING_REVIEW_MODAL], showReviewApprovalModal, (action) => {
      const { reviewId } = action.payload;
      return {
        parent_resource_id,
        review_id: reviewId,
      };
    })
    .match([BI_REVIEW_SUBMITTED_FOR_APPROVAL], newReviewSubmittedForApproval, (action) => {
      const { reviewId } = action.payload;
      const reviewState = getReviewState(reviewId, reviewsState);
      if (reviewState) {
        const { review } = reviewState;
        return {
          author_role,
          image_count: getReviewImageCount(review),
          video_count: getReviewVideoCount(review),
          parent_resource_id,
          rating: review.content.rating,
          review_id: review.id,
          review_text: review.content.body ?? '',
          review_title: review.content.title ?? '',
          referral_info: getReferer(),
          approved_by: '',
          approver_role: '',
        };
      }
    })
    .match([BI_CLICK_CLOSE_PENDING_REVIEW_MODAL], closeReviewApprovalModal, (action) => {
      const { origin, reviewId } = action.payload;
      return {
        parent_resource_id,
        origin,
        review_id: reviewId,
      };
    })
    .match([BI_FORM_VALIDATION_ERROR], reviewSubmissionWarning, (action) => {
      const { reviewId = '', missingRequiredFields } = action.payload;
      return {
        author_role,
        review_id: reviewId,
        warning_reason: missingRequiredFields.join(','),
      };
    })
    .match([BI_FOCUS_FORM_BODY], intentClickIntoReviewSection, () => {
      return {
        author_role,
        origin:
          appState.type === 'READY'
            ? appState.topSection.type === 'EMPTY'
              ? 'empty_state'
              : 'product_page'
            : '',
        parent_resource_id,
      };
    })
    .execute(_action);
};
