import { AppThunk, AllActions } from '../../store-types';
import { ErrorInfo, ReviewCreateError } from '../../../common-types';
import { isOfType } from '~/ts-utils';
import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import { getReviewCreateError, getGenericError } from '../reviews-errors';
import { FormEffect, ReviewContent } from './review-state-types';
import { CreateAs, Review } from '~reviews/controller/lib/reviews-api-types';
import { getReviewState } from './reviews-state-selectors';
import { getIsEditor, getSiteIsTemplate } from '../../base-params/base-params-selectors';
import { uniqueId } from 'lodash';
import { biReviewDeleted, biReviewPublishSuccess } from '~reviews/common/store/actions/bi-actions';
import { requestLogin } from '~reviews/common/store/actions/request-login';
import { ModerationStatus } from '@wix/ambassador-reviews-v1-enriched-review/types';

export const CANCEL_CREATING_REVIEW = 'CANCEL_CREATING_REVIEW' as const;
export const CANCEL_EDITING_REVIEW = 'CANCEL_EDITING_REVIEW' as const;
export const EDIT_REVIEW = 'EDIT_REVIEW' as const;

export const CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST' as const;
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS' as const;
export const CREATE_REVIEW_FAILURE = 'CREATE_REVIEW_FAILURE' as const;

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST' as const;
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS' as const;
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE' as const;

export const UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST' as const;
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS' as const;
export const UPDATE_REVIEW_FAILURE = 'UPDATE_REVIEW_FAILURE' as const;

export const cancelCreatingReview = (payload: {}) => ({
  type: CANCEL_CREATING_REVIEW,
  payload,
});

export const cancelEditingReview = (payload: { reviewId: string }) => ({
  type: CANCEL_EDITING_REVIEW,
  payload,
});

export const editReview = (payload: { reviewId: string }) => ({
  type: EDIT_REVIEW,
  payload,
});

export const createReviewRequest = (payload: { requestId: string }) => ({
  type: CREATE_REVIEW_REQUEST,
  payload,
});

export const createReviewSuccess = (payload: {
  requestId: string;
  review: Review;
  requestDuration: number;
}) => ({
  type: CREATE_REVIEW_SUCCESS,
  payload,
});

export const createReviewFailure = (payload: { requestId: string; error: ReviewCreateError }) => ({
  type: CREATE_REVIEW_FAILURE,
  payload,
});

export function createReview({
  content,
  createAs,
}: {
  content: ReviewContent;
  createAs: CreateAs;
}): AppThunk {
  return async (dispatch, getState, { wixReviewsApi, executeBeforeCrud }) => {
    if (getSiteIsTemplate(getState())) {
      return dispatch(requestLogin());
    }
    const requestId = uniqueId();
    const { reviews: reviewsState, configuration } = getState();
    if (reviewsState.type !== 'READY') {
      return;
    }
    dispatch(
      createReviewRequest({
        requestId,
      }),
    );

    const interceptorResult = await executeBeforeCrud({
      type: 'REVIEW',
      operation: 'CREATE',
      content,
    });
    if (interceptorResult.type === 'ERROR') {
      dispatch(
        createReviewFailure({
          requestId,
          error: { type: 'CUSTOM', message: interceptorResult.message },
        }),
      );
      return;
    }

    const requestStartTime = Date.now();
    const promise = wixReviewsApi.createReview({
      reviewContent: interceptorResult.content as ReviewContent,
      resourceId: reviewsState.config.resourceId,
      namespace: reviewsState.config.namespace,
      createAs,
      isModerationEnabled: configuration.moderateContent ?? false,
    });

    try {
      const response = await promise;
      dispatch(
        createReviewSuccess({
          requestId,
          review: response,
          requestDuration: Date.now() - requestStartTime,
        }),
      );
      if (response.moderationStatus === ModerationStatus.APPROVED) {
        dispatch(biReviewPublishSuccess({ review: response }));
      }
    } catch (e) {
      return dispatch(
        createReviewFailure({
          requestId,
          error: getReviewCreateError(e as AmbassadorHTTPError),
        }),
      );
    }
  };
}

export const deleteReviewRequest = (payload: { reviewId: string }) => ({
  type: DELETE_REVIEW_REQUEST,
  payload,
});

export const deleteReviewSuccess = (payload: { reviewId: string }) => ({
  type: DELETE_REVIEW_SUCCESS,
  payload,
});

export const deleteReviewFailure = (payload: { reviewId: string; error: ErrorInfo }) => ({
  type: DELETE_REVIEW_FAILURE,
  payload,
});

export function deleteReview({ reviewId }: { reviewId: string }): AppThunk {
  return async (dispatch, getState, { wixReviewsApi }) => {
    if (getSiteIsTemplate(getState())) {
      return dispatch(requestLogin());
    }
    const reviewsState = getState().reviews;
    if (reviewsState?.type !== 'READY') {
      return;
    }
    const reviewState = getReviewState(reviewId, reviewsState);
    if (reviewState?.type !== 'REVIEW_READY') {
      return;
    }

    dispatch(deleteReviewRequest({ reviewId }));

    return wixReviewsApi
      .deleteReview({ reviewId })
      .then(() => {
        dispatch(biReviewDeleted({ reviewId }));
        dispatch(deleteReviewSuccess({ reviewId }));
      })
      .catch((e: AmbassadorHTTPError) =>
        dispatch(deleteReviewFailure({ reviewId, error: getGenericError(e.httpStatus) })),
      );
  };
}

export const updateReviewRequest = (payload: { reviewId: string; content: ReviewContent }) => ({
  type: UPDATE_REVIEW_REQUEST,
  payload,
});

export const updateReviewSuccess = (payload: {
  reviewId: string;
  review: Review;
  requestDuration: number;
}) => ({
  type: UPDATE_REVIEW_SUCCESS,
  payload,
});

export const updateReviewFailure = (payload: { reviewId: string; error: ErrorInfo }) => ({
  type: UPDATE_REVIEW_FAILURE,
  payload,
});

export function updateReview({
  reviewId,
  content,
  updatedFields,
}: {
  content: ReviewContent;
  reviewId: string;
  updatedFields: (keyof ReviewContent)[];
}): AppThunk {
  return async (dispatch, getState, { wixReviewsApi, executeBeforeCrud }) => {
    if (getSiteIsTemplate(getState())) {
      return dispatch(requestLogin());
    }
    const { reviews: reviewsState, configuration } = getState();
    if (reviewsState?.type !== 'READY') {
      return;
    }
    const reviewState = getReviewState(reviewId, reviewsState);
    if (reviewState?.type !== 'REVIEW_EDITING') {
      return;
    }

    dispatch(updateReviewRequest({ reviewId, content }));

    const interceptorResult = await executeBeforeCrud({
      type: 'REVIEW',
      operation: 'UPDATE',
      content,
    });
    if (interceptorResult.type === 'ERROR') {
      dispatch(
        updateReviewFailure({
          reviewId,
          error: { type: 'CUSTOM', message: interceptorResult.message },
        }),
      );
      return;
    }

    const requestStartTime = Date.now();

    return wixReviewsApi
      .updateReview({
        reviewContent: interceptorResult.content as ReviewContent,
        reviewId,
        updatedFields,
        author: reviewState.review.author,
        isModerationEnabled: configuration?.moderateContent ?? false,
      })
      .then((response) => {
        dispatch(
          updateReviewSuccess({
            reviewId,
            review: response,
            requestDuration: Date.now() - requestStartTime,
          }),
        );
        if (response.moderationStatus === ModerationStatus.APPROVED) {
          dispatch(biReviewPublishSuccess({ review: response, isEdited: true }));
        }
      })
      .catch((e: AmbassadorHTTPError) =>
        dispatch(updateReviewFailure({ reviewId, error: getGenericError(e.httpStatus) })),
      );
  };
}

export const CALL_TO_ACTION_CLICKED = 'CALL_TO_ACTION_CLICKED' as const;
export const callToActionClicked = (payload: { button: 'create' | 'view' }) => ({
  type: CALL_TO_ACTION_CLICKED,
  payload,
});

export const OPEN_REVIEW_FORM_SUCCESS = 'OPEN_REVIEW_FORM_SUCCESS' as const;
export const CLOSE_REVIEW_FORM_SUCCESS = 'CLOSE_REVIEW_FORM_SUCCESS' as const;
export const SET_FORM_EFFECT_EVENT = 'SET_FORM_EFFECT_EVENT' as const;

export const openReviewFormSuccess = (payload: {}) => ({
  type: OPEN_REVIEW_FORM_SUCCESS,
  payload,
});

export const closeReviewFormSuccess = (payload: {}) => ({
  type: CLOSE_REVIEW_FORM_SUCCESS,
  payload,
});

export const openReviewForm = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const reviewsState = state.reviews;
  if (
    reviewsState.type !== 'READY' ||
    (reviewsState.userReview.type === 'CREATED' && !getIsEditor(state))
  ) {
    return;
  }
  dispatch(openReviewFormSuccess({}));
};

export const setFormEffectEventSuccess = (payload: FormEffect) => ({
  type: SET_FORM_EFFECT_EVENT,
  payload,
});

export const setFormEffect =
  (payload: FormEffect): AppThunk =>
  (dispatch) => {
    dispatch(setFormEffectEventSuccess(payload));
  };

export const closeReviewForm = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const reviewsState = state.reviews;
  if (
    reviewsState.type !== 'READY' ||
    (reviewsState.userReview.type === 'CREATED' && !getIsEditor(state))
  ) {
    return;
  }
  dispatch(closeReviewFormSuccess({}));
};

export type CreateReviewActions =
  | ReturnType<typeof createReviewSuccess>
  | ReturnType<typeof createReviewRequest>
  | ReturnType<typeof createReviewFailure>;

export type UpdateReviewActions =
  | ReturnType<typeof updateReviewSuccess>
  | ReturnType<typeof updateReviewRequest>
  | ReturnType<typeof updateReviewFailure>;

export type DeleteReviewActions =
  | ReturnType<typeof deleteReviewSuccess>
  | ReturnType<typeof deleteReviewRequest>
  | ReturnType<typeof deleteReviewFailure>;

export type CancelCreatingReview = ReturnType<typeof cancelCreatingReview>;
export type CancelEditingReview = ReturnType<typeof cancelEditingReview>;
export type EditReview = ReturnType<typeof editReview>;

export type CallToActionClicked = ReturnType<typeof callToActionClicked>;

export type OpenReviewForm = ReturnType<typeof openReviewFormSuccess>;
export type CloseReviewForm = ReturnType<typeof closeReviewFormSuccess>;

export type SetFormEffect = ReturnType<typeof setFormEffectEventSuccess>;

export type ReviewsCrudAction =
  | CreateReviewActions
  | UpdateReviewActions
  | DeleteReviewActions
  | CancelCreatingReview
  | CancelEditingReview
  | EditReview
  | CallToActionClicked
  | OpenReviewForm
  | CloseReviewForm
  | SetFormEffect;

export const isReviewsCrudAction: (action: AllActions) => action is ReviewsCrudAction = isOfType([
  CANCEL_CREATING_REVIEW,
  CANCEL_EDITING_REVIEW,
  EDIT_REVIEW,

  CREATE_REVIEW_REQUEST,
  CREATE_REVIEW_SUCCESS,
  CREATE_REVIEW_FAILURE,

  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAILURE,

  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAILURE,

  CALL_TO_ACTION_CLICKED,

  OPEN_REVIEW_FORM_SUCCESS,
  CLOSE_REVIEW_FORM_SUCCESS,
  SET_FORM_EFFECT_EVENT,
]);
